export default [
  {
    key: 'orderCode',
    label: 'field.orderCode',
    stickyColumn: true,
    variant: 'light',
    width: 6000,
  },
  {
    key: 'regionCode',
    label: 'field.regionCode',
    width: 2500,
  },
  {
    key: 'agentCode',
    label: 'field.agentCode',
    width: 2500,
  },
  {
    key: 'createdAt',
    label: 'field.orderedDate',
  },
  {
    key: 'expectDeliveryDate',
    label: 'field.expectDeliveryDate',
  },
  {
    key: 'salespersonIdCard',
    label: 'field.seIdCard',
  },
  {
    key: 'salespersonName',
    label: 'field.seName',
  },
  {
    key: 'outletCode',
    label: 'field.outletCode',
    width: 3500,
  },
  {
    key: 'outletName',
    label: 'field.outletName',
    localization: true,
    width: 6000,
  },
  {
    key: 'outletSegmentName',
    label: 'field.outletSegment',
    localization: true,
  },
  {
    key: 'productCode',
    label: 'field.productCode',
    width: 3500,
  },
  {
    key: 'productName',
    label: 'field.productName',
    localization: true,
    width: 6000,
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 3000,
  },
  {
    key: 'unitPrice',
    label: 'field.unitPrice',
    width: 3000,
  },
  {
    key: 'total',
    label: 'field.total',
    width: 3000,
  },
  {
    key: 'type',
    label: 'field.from',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 2500,
  },
  {
    key: 'state',
    label: 'field.state',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 3000,
  },
  {
    key: 'isOnRoute',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 2500,
    options: ["", ""]
  },
  {
    key: 'orderChannel',
    label: 'field.channel',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 2500,
    options: ["", "", ""]
  },
  {
    key: 'promotionDescription',
    label: 'field.promotion',
    width: 8000,
  },
];
