export default [
  {
    key: 'regionId',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'agentId',
    label: 'field.agent',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'agent',
    selectionKey: 'id',
    selectionLabel: 'agentCode',
  },
  {
    key: 'outletId',
    label: 'field.outlet',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'outlet',
    selectionKey: 'id',
    selectionLabel: 'label',
    localization: true,
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'productId',
    label: 'field.product',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'product',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'orderCode',
    label: 'field.orderCode',
    type: 'text',
  },
  {
    key: 'outletSegmentId',
    label: 'field.outletSegment',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'outletSegment',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'channel',
    label: 'field.channel',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'field.onPremise', value: '1' },
      { text: 'field.offPremise', value: '2' },
    ],
  },
  {
    key: 'createdAt',
    label: 'field.orderedDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
]
